<!--
Required: portfolio_id, yearIdActive
Provides: sustainabilityIndicatorFactors
-->
<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState({
      // sustainabilityIndicatorFactors: (state) => state.sustainabilityIndicatorFactors.sustainabilityIndicatorFactors,
      sustainabilityIndicatorFactorsLoading: (state) => state.sustainabilityIndicatorFactors.loading,
      sustainabilityIndicatorFactorsLoaded: (state) => state.sustainabilityIndicatorFactors.loaded,
    }),

    ...mapGetters({
      __sustainabilityIndicatorFactors: 'sustainabilityIndicatorFactors/getSustainabilityIndicatorFactors',
    }),

    sustainabilityIndicatorFactors() {
      return this.__sustainabilityIndicatorFactors()
    },
  },

  watch: {
    portfolio_id() {
      this.__loadSustainabilityIndicatorFactorsByPortfolioIdAndYearId({
        portfolioId: this.portfolio_id,
        yearId: this.yearIdActive,
      })
    },

    yearIdActive() {
      this.__loadSustainabilityIndicatorFactorsByPortfolioIdAndYearId({
        portfolioId: this.portfolio_id,
        yearId: this.yearIdActive,
      })
    },

    // sustainabilityIndicatorFactorsLoaded() {
    //   this.__sustainabilityIndicatorFactors()
    // },
  },

  created() {
    this.__loadSustainabilityIndicatorFactorsByPortfolioIdAndYearId({
      portfolioId: this.portfolio_id,
      yearId: this.yearIdActive,
    })
  },

  methods: {
    ...mapActions({
      __loadSustainabilityIndicatorFactorsByPortfolioIdAndYearId:
        'sustainabilityIndicatorFactors/loadSustainabilityIndicatorFactorsByPortfolioIdAndYearId',
    }),
  },
}
</script>

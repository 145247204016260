<i18n>
{
  "de": {
    "usageTitle": "Nutzung",
    "usageInfoBoxTitle": "Nutzung",
    "usageInfoBox": "Mit Hilfe von Nutzungen können einem Energieträger unterschiedliche Faktoren zugeordnet werden.<br/><br/>Beispiel:<br/><br/>Angenommen in einer Liegenschaft wird PV-Strom produziert. Dieser wird auf Allgemeinstrom, Mieterstrom verteilt und zusätzlich auch rückgespiesen. Bei der Auswertung wird dann der Stromanteil, der als  Allgemeinstrom genutzt wird mit den Faktoren der Standardnutzung verrechnet. Derjenige Strom der als Mieterstrom genutzt wird, wird mit den Faktoren der Mieternutzung verrechnet und der Strom der rückgespiesen wird, wird mit den Faktoren der Rückspeisung verrechnet.<br/><br/>Wenn keine spezifische Nutzung definiert ist, werden die Werte der Standardnutzung verwendet.<br/><br/>Um eine Nutzung wieder zu löschen, müssen lediglich alle Werte der Nutzung gelöscht werden.",
    "usages": {
      "STANDARD": "Standard",
      "TENANTS": "Mieter",
      "FEED_IN": "Rückspeisung"
    },
    "sustainabilityIndicatorIdentifier": "Faktor-Identifikator",
    "kwhPerUnit": "kWh/Einheit",
    "caloricValueTitle": "Brennwert",
    "caloricValueInfoBoxTitle": "Brennwert",
    "caloricValueInfoBox": "Brennwert bezogen auf die jeweilige Einheit (Volumen/Energie). Standardwerte in SIA 380:2015 Anhang B Tabelle 4",
    "reidaCarrierTitle": "REIDA Äquivalent",
    "reidaCarrierInfoBoxTitle": "REIDA Äquivalent",
    "reidaCarrierInfoBox": "Energieträger Typ der für die Erstellung des REIDA CO₂e-Reports benutzt wird. (siehe Tabelle REIDA Äquivalente)",
    "factorMeta": {
      "title": {
        "s1e": "Scope 1 EF",
        "s2e": "Scope 2 EF",
        "s3e": "Scope 3 EF",
        "s12e": "Scope 1-2 EF",
        "s123e": "Scope 1-3 EF",
        "pe": "Primärenergie (PEF)",
        "per": "PEF Erneuerbar",
        "penr": "PEF Fossil"
      },
      "infoBox": {
        "title": {
          "s1e": "Scope 1 Emissionsfaktor Treibhausgase",
          "s2e": "Scope 2 Emissionsfaktor Treibhausgase",
          "s3e": "Scope 3 Emissionsfaktor Treibhausgase",
          "s12e": "Scope 1-2 Emissionsfaktor Treibhausgase",
          "s123e": "Scope 1-3 Emissionsfaktor Treibhausgase",
          "pe": "Primärenergiefaktor (PEF)",
          "per": "Primärenergiefaktor Erneuerbar",
          "penr": "Primärenergiefaktor Fossil"
        },
        "body": {
          "s1e": "Emissionsfaktor bezogen auf Brennwert des Energieträgers. Standardwerte in SIA 380:2015 Anhang C Tabelle 5",
          "s2e": "Emissionsfaktor bezogen auf Brennwert des Energieträgers.",
          "s3e": "Emissionsfaktor bezogen auf Brennwert des Energieträgers.",
          "s12e": "Summe der Scope 1 und Scope 2 Emissionsfaktoren.",
          "s123e": "Summe der Scope 1, Scope 2 und Scope 3 Emissionsfaktoren.",
          "pe": "Emissionsfaktor bezogen auf Brennwert des Energieträgers. Standardwerte in SIA 380:2015 Anhang C Tabelle 5",
          "per": "Emissionsfaktor bezogen auf Brennwert des Energieträgers.",
          "penr": "Emissionsfaktor bezogen auf Brennwert des Energieträgers."
        }
      },
      "validation": {
        "title": {
          "pe": "Primärenergiefaktoren stimmen nicht überein",
          "s12e": "Scope 1-2 EF stimmen nicht überein",
          "s123e": "Scope 1-3 EF stimmen nicht überein"
        },
        "body": {
          "pe": "Die Faktoren müssen folgende Gleichung erfüllen: PEF = PEF fossil + PEF erneuerbar (erwarteter Wert: {expected})",
          "s12e": "Die Faktoren müssen folgende Gleichung erfüllen: Scope 1-2 EF = Scope 1 EF + Scope 2 EF (erwarteter Wert: {expected})",
          "s123e": "Die Faktoren müssen folgende Gleichung erfüllen: Scope 1-3 EF = Scope 1 EF + Scope 2 EF + Scope 3 EF (erwarteter Wert: {expected})"
        }
      }
    },
    "initialYear": "Initial",
    "sourcedInfo": "{source} (Jahr: {year})",
    "source": {
      "STANDARD": "Standardnutzung",
      "MANUAL": "Manuell",
      "FALLBACK": "Ersatzwert",
      "INFERRED": "Abgeleitet"
    }
  }
}
</i18n>

<template>
  <DetailList has-header class="energy-carriers-table">
    <!-- Headers -->
    <template #header>
      <!-- Energy Carrier Name Block -->
      <div class="sticky-column column--name-container">
        <div class="column--name">
          <h3>{{ title }}</h3>
          <!-- Spacer: buttons -->
          <div v-if="getPortfolioPermission('EDIT_ENERGY_CARRIERS')" class="column--buttons"></div>
        </div>
      </div>
      <!-- Brennwert -->
      <HeaderEntry
        v-if="isTableExtended"
        :title="$t('caloricValueTitle')"
        :unit="$t('kwhPerUnit')"
        :infobox-title="$t('caloricValueInfoBoxTitle')"
        :infobox-text="$t('caloricValueInfoBox')"
        align-right
        class="column--units"
      />
      <!-- REIDA equivalent-->
      <div v-if="isTableExtended" class="column--reida-equivalent-container">
        <span>{{ $t('reidaCarrierTitle') }}</span>
        <InfoBox :title="$t('reidaCarrierInfoBoxTitle')">{{ $t('reidaCarrierInfoBox') }}</InfoBox>
      </div>
      <!-- Usage -->
      <div class="sticky-column sticky-column--usage" :class="{ 'line-left': isTableExtended }">
        <div class="column--usage">
          <div>
            <span>{{ $t('usageTitle') }}</span>
            <InfoBox :title="$t('usageInfoBoxTitle')">
              <span v-html="$t('usageInfoBox')"></span>
            </InfoBox>
          </div>
          <!-- Spacer: buttons -->
          <div v-if="getPortfolioPermission('EDIT_ENERGY_CARRIERS')" class="column--buttons"></div>
          <!-- TBD: Filtering by column values -->
          <!-- <FilterableListColumnHeader v-model="usageFilter" :title="$t('usageTitle')" /> -->
        </div>
      </div>
      <!-- Sustainability indicators -->
      <li class="si-factors">
        <div
          v-for="identifier in factorOrder.filter((f) => isTableExtended || f.showAlways)"
          :key="identifier.value"
          class="align-right"
        >
          <HeaderEntry :title="$t(`factorMeta.title.${identifier.value}`)" :unit="factorUnits[identifier.value]">
            <template #infobox>
              <InfoBox :title="$t(`factorMeta.infoBox.title.${identifier.value}`)">
                <p>
                  {{ $t(`factorMeta.infoBox.body.${identifier.value}`) }}
                </p>
                <p>{{ $t('sustainabilityIndicatorIdentifier') }}: {{ identifier.value }}</p>
              </InfoBox>
            </template>
          </HeaderEntry>
        </div>
      </li>
    </template>

    <template v-if="sortedEnergyCarriers">
      <!-- Rows, data -->
      <li v-for="(energyCarrier, i) in sortedEnergyCarriers" :key="i">
        <!-- Energy Carrier Name -->
        <div class="sticky-column column--name-container">
          <div class="column--name">
            <div v-if="energyCarrier.default" class="name" :title="$t(`_energyCarriers.${energyCarrier.name}`)">
              {{ $t(`_energyCarriers.${energyCarrier.name}`) }}
            </div>
            <div v-else class="name" :title="energyCarrier.name">{{ energyCarrier.name }}</div>
            <!-- Edit button -->
            <template v-if="getPortfolioPermission('EDIT_ENERGY_CARRIERS')">
              <ListButtonWrapper class="column--buttons">
                <ListEditButton @click="() => $emit('edit-energy-carrier', energyCarrier)" />
              </ListButtonWrapper>
            </template>
          </div>
        </div>
        <!-- Brennwert -->
        <div v-if="isTableExtended" class="column--units align-right">
          {{ energyCarrier.caloric_value }}
        </div>
        <!-- REIDA Equivalent -->
        <div v-if="isTableExtended" class="column--reida-equivalent-container">
          <div
            v-if="energyCarrier.reida_identifier"
            :title="getReidaNameFromIdentifier(energyCarrier.reida_identifier)"
            class="reida-equivalent"
          >
            {{ getReidaNameFromIdentifier(energyCarrier.reida_identifier) }}
          </div>
          <div v-else>-</div>
        </div>

        <!-- Usages + Sustainability indicator factors -->
        <div class="sticky-column sticky-column--usage" :class="{ 'line-left': isTableExtended }">
          <!-- Usage name + button -->
          <template
            v-for="(sustainabilityIndicator, j) in sortedEnergyCarrierUsages?.filter(
              (si) => si.ec_name === energyCarrier.name
            )"
          >
            <div :key="j" class="column--usage">
              <div>{{ $t(`usages.${sustainabilityIndicator.usage}`) }}</div>
              <!-- Edit button -->
              <ListButtonWrapper v-if="getPortfolioPermission('EDIT_ENERGY_CARRIERS')" class="column--buttons">
                <ListEditButton @click="() => $emit('edit-usage', energyCarrier, sustainabilityIndicator)" />
              </ListButtonWrapper>
            </div>
          </template>
        </div>
        <div>
          <!-- Sustainability indicator factors-->
          <template
            v-for="(sustainabilityIndicator, k) in sortedEnergyCarrierUsages?.filter(
              (si) => si.ec_name === energyCarrier.name
            )"
          >
            <li :key="k" class="si-factors">
              <div
                v-for="identifier in factorOrder.filter((f) => isTableExtended || f.showAlways)"
                :key="identifier.value"
                :class="valueClasses(sustainabilityIndicator, identifier.value)"
                class="factor"
              >
                <span v-if="sustainabilityIndicator.factors[identifier.value].manual !== null">
                  {{ sustainabilityIndicator.factors[identifier.value].manual }}
                </span>
                <span v-else>
                  {{ sustainabilityIndicator.factors[identifier.value].inherited }}
                </span>

                <!-- Source -->
                <span v-if="sustainabilityIndicator.factors[identifier.value].manual === null" class="source-anchor">
                  <span class="source-container">
                    <span class="source">
                      <template
                        v-if="
                          ['FALLBACK', 'INFERRED'].includes(sustainabilityIndicator.factors[identifier.value].source)
                        "
                      >
                        {{ $t(`source.${sustainabilityIndicator.factors[identifier.value].source}`) }}
                      </template>
                      <template v-else>
                        {{
                          $t('sourcedInfo', {
                            source: $t(`source.${sustainabilityIndicator.factors[identifier.value].source}`),
                            year:
                              sustainabilityIndicator.factors[identifier.value].source_year === 0
                                ? $t('initialYear')
                                : sustainabilityIndicator.factors[identifier.value].source_year,
                          })
                        }}
                      </template>
                    </span>
                  </span>
                </span>
              </div>
            </li>
          </template>
        </div>
      </li>
    </template>
  </DetailList>
</template>

<script>
import { mapGetters } from 'vuex'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListEditButton from '@/components/shared/lists/ListEditButton.vue'
import InfoBox from '@/components/shared/InfoBox.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import HeaderEntry from '@/components/shared/lists/HeaderEntry.vue'

export default {
  name: 'EnergyCarriersTable',

  components: {
    //
    DetailList,
    InfoBox,
    ListEditButton,
    ListButtonWrapper,
    HeaderEntry,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    isTableExtended: {
      type: Boolean,
      default: false,
    },
    sortedEnergyCarriers: {
      type: Array,
      required: true,
    },
    sortedEnergyCarrierUsages: {
      type: Array,
      required: true,
    },
    reidaOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      // Order of columns in table
      factorOrder: [
        //
        { value: 's1e', showAlways: true },
        { value: 's2e', showAlways: true },
        { value: 's3e', showAlways: true },
        { value: 's12e', showAlways: false },
        { value: 's123e', showAlways: false },
        { value: 'pe', showAlways: true },
        { value: 'penr', showAlways: true },
        { value: 'per', showAlways: false },
      ],
    }
  },

  computed: {
    ...mapGetters({
      __sustainabilityIndicators: 'portfolio/getSustainabilityIndicators',
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),

    //
    factorUnits() {
      return this.__sustainabilityIndicators().reduce((obj, si) => ({ ...obj, [si.identifier]: si?.unit }), {})
    },
  },

  methods: {
    //
    valueClasses(energyCarrier, identifier) {
      const manual = energyCarrier.factors[identifier].manual !== null
      return {
        'align-right': true,
        inherited: !manual, // Display inherited values in light grey
        bold: manual, // Display user-defined values in bold
      }
    },

    //
    getReidaNameFromIdentifier(id) {
      const ec = this.reidaOptions.find((obj) => obj.id === id)
      return ec === undefined ? '' : ec.name
    },
  },
}
</script>

<style lang="scss">
.energy-carriers-table.detail-list {
  max-width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  overflow: hidden;

  & > ul {
    overflow: auto;
    border: none !important;
    border-radius: 0;
    width: 100%;
    max-height: 70svh;

    & li {
      min-width: fit-content;
      width: 100%;
      align-items: stretch;
      justify-content: flex-start;
      padding: 0 10px 0 0 !important;
      gap: 0px !important;
    }

    & > li > * {
      flex: 1;
      min-width: 200px;
      width: 100%;

      padding: 0;
    }

    & .si-factors {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0 10px 0 0 !important;
      height: 56px;
      overflow: hidden;

      & > * {
        flex: 1;
        min-width: 200px;
        width: 100%;
      }
    }

    & .si-factors + .si-factors {
      border-top: 1px solid #ddd;
    }
  }

  & .column--buttons {
    min-width: initial;
    min-height: 2rem;
    width: 58px;
    flex: none;
    padding-bottom: 0;
    padding-right: 10px;
  }

  & .column--units {
    min-width: initial;
    width: 140px;
    height: 56px;
    display: flex;
    align-items: center;
    flex: none;
    padding: 0 10px !important;
  }

  & .column--name-container,
  .column--usage {
    display: flex;
    flex-direction: row;
    min-width: initial;
    flex: none;
    min-height: 56px;
    padding: 0 0 0 12px !important;
  }

  & .column--name-container {
    width: 250px;

    & .column--name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 56px;

      & .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  & .column--reida-equivalent-container {
    min-width: initial;
    width: 240px;
    height: 56px;
    display: flex;
    align-items: center;
    flex: none;
    padding: 0 10px !important;

    & .reida-equivalent {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & .column--usage {
    align-items: center;
    justify-content: space-between;
    width: 200px;
    height: 56px;
  }

  & .inherited {
    color: #888;
  }

  & .bold {
    color: var(--text-color);
    font-weight: 400;
  }

  & .sticky-column--usage {
    flex: 0;
    left: 250px;
    border-right: 1px solid #ddd;

    & > * + * {
      border-top: 1px solid #ddd;
    }
  }

  .line-left {
    border-left: 1px solid #ddd;
  }
}
</style>

<style lang="scss" scoped>
.factor {
  position: relative;
  transition: margin-bottom 0.2s ease-in-out;

  & .source-anchor {
    display: block;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    &:has(> .source-anchor) {
      margin-bottom: 12px;
    }

    & .source-anchor {
      opacity: 1;
    }
  }
}

.source-anchor {
  position: absolute;
  right: 0;
}

.source-container {
  color: var(--secondary-text-color);
  font-size: var(--font-xs);
  display: flex;
  justify-content: flex-end;
}
</style>

<!--
Required: portfolio_id
Provides: sustainabilityYears
-->
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      sustainabilityYearsLoading: (state) => state.sustainabilityYears.loading,
      sustainabilityYearsLoaded: (state) => state.sustainabilityYears.loaded,
    }),

    ...mapGetters({
      __sustainabilityYears: 'sustainabilityYears/getSustainabilityYears',
    }),

    sustainabilityYears() {
      return this.__sustainabilityYears()
    },
  },

  watch: {
    portfolio_id() {
      this.__loadSustainabilityYearsByPortfolioId(this.portfolio_id)
    },
  },

  created() {
    this.__loadSustainabilityYearsByPortfolioId(this.portfolio_id)
  },

  methods: {
    ...mapActions({
      __loadSustainabilityYearsByPortfolioId: 'sustainabilityYears/loadSustainabilityYearsByPortfolioId',
    }),
  },
}
</script>

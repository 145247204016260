<template>
  <button type="button" class="button" @click="$emit('change', !expanded)">
    <div class="center">
      <template v-if="expanded">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-minimize-2"
          :style="`width: ${size}px;`"
        >
          <polyline points="4 14 10 14 10 20"></polyline>
          <polyline points="20 10 14 10 14 4"></polyline>
          <line x1="14" y1="10" x2="21" y2="3"></line>
          <line x1="3" y1="21" x2="10" y2="14"></line>
        </svg>
      </template>

      <template v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-maximize-2"
          :style="`width: ${size}px;`"
        >
          <polyline points="15 3 21 3 21 9"></polyline>
          <polyline points="9 21 3 21 3 15"></polyline>
          <line x1="21" y1="3" x2="14" y2="10"></line>
          <line x1="3" y1="21" x2="10" y2="14"></line>
        </svg>
      </template>
    </div>
  </button>
</template>

<script>
export default {
  name: 'ExpandToggle',

  model: {
    prop: 'expanded',
    event: 'change',
  },

  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
    size: {
      type: Number,
      default: 24,
    },
  },
}
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;

  svg {
    width: 100%;
    height: auto;
  }
}
</style>

<i18n>
{
  "de": {
    "nameTitle": "Name",
    "scopeTitle": "Scope",
    "scopeInfoBoxTitle": "Scope",
    "scopeInfoBox": "Die Scope (1 oder 2) in welcher Energieträger eines Typen Emissionen generel verursachen",
    "fossilCo2FactorTitle": "Direkter Emissionsfaktor (CO₂)",
    "fossilCo2FactorInfoBoxTitle": "Direkter Emissionsfaktor (CO₂)",
    "fossilCo2FactorInfoBox": "Direkter Emissionsfaktor (CO₂) bezogen auf den Endenergieverbrauch",
    "fossilThgFactorTitle": "Direkter Emissionsfaktor (andere Treibhausgase)",
    "fossilThgFactorInfoBoxTitle": "Direkter Emissionsfaktor (andere Treibhausgase)",
    "fossilThgFactorInfoBox": "Direkter Emissionsfaktor (andere Treibhausgase) bezogen auf den Endenergieverbrauch",
    "anergenicEmissionTitle": "Direkter Anergener Emissionsfaktor",
    "anergenicEmissionInfoBoxTitle": "Direkter Anergener Emissionsfaktor",
    "anergenicEmissionInfoBox": "Faktor der direkten Anergenen Emission bezogen auf den Endenergieverbrauch",
    "biogenicEmissionTitle": "Direkter Biogener Emissionsfaktor",
    "biogenicEmissionInfoBoxTitle": "Direkter Biogener Emissionsfaktor",
    "biogenicEmissionInfoBox": "Faktor der direkten Biogenen Emission bezogen auf den Endenergieverbrauch",
    "upstreamFossilCo2FactorTitle": "Upstream Emissionsfaktor (CO₂)",
    "upstreamFossilCo2FactorInfoBoxTitle": "Upstream Emissionsfaktor (CO₂)",
    "upstreamFossilCo2FactorInfoBox": "Upstream Emissionsfaktor (CO₂) bezogen auf den Endenergieverbrauch",
    "upstreamFossilThgFactorTitle": "Upstream Emissionsfaktor (andere Treibhausgase)",
    "upstreamFossilThgFactorInfoBoxTitle": "Upstream Emissionsfaktor (andere Treibhausgase)",
    "upstreamFossilThgFactorInfoBox": "Upstream Emissionsfaktor (andere Treibhausgase) bezogen auf den Endenergieverbrauch",
    "upstreamAnergenicEmissionTitle": "Upstream Anergener Emissionsfaktor",
    "upstreamAnergenicEmissionInfoBoxTitle": "Upstream Anergener Emissionsfaktor",
    "upstreamAnergenicEmissionInfoBox": "Faktor der upstream Anergenen Emission bezogen auf den Endenergieverbrauch",
    "upstreamBiogenicEmissionTitle": "Upstream Biogener Emissionsfaktor",
    "upstreamBiogenicEmissionInfoBoxTitle": "Upstream Biogener Emissionsfaktor",
    "upstreamBiogenicEmissionInfoBox": "Faktor der upstream Biogenen Emission bezogen auf den Endenergieverbrauch",
    "fossilShareTitle": "Fossiler Anteil",
    "fossilShareInfoBoxTitle": "Fossiler Anteil",
    "fossilShareInfoBox": "Anteil fossiler Energie bezogen auf den Endenergieverbrauch",
    "renewableShareTitle": "Erneuerbarer Anteil",
    "renewableShareInfoBoxTitle": "Erneuerbarer Anteil",
    "renewableShareInfoBox": "Anteil erneuerbarer Energie bezogen auf den Endenergieverbrauch",
    "wasteHeatTitle": "Abwärme",
    "wasteHeatInfoBoxTitle": "Abwärme",
    "wasteHeatInfoBox": "Anteil Abwärme bezogen auf den Endenergieverbrauch"
  }
}
</i18n>

<template>
  <div class="reida-energy-carriers-table">
    <DetailList has-header>
      <!-- Headers -->
      <template #header>
        <div>
          <span>{{ $t('nameTitle') }}</span>
        </div>
        <HeaderEntry
          :title="$t('fossilCo2FactorTitle')"
          unit="kg CO₂e/kWh"
          :infobox-title="$t('fossilCo2FactorInfoBoxTitle')"
          :infobox-text="$t('fossilCo2FactorInfoBox')"
          align-right
        />
        <HeaderEntry
          :title="$t('fossilThgFactorTitle')"
          unit="kg CO₂e/kWh"
          :infobox-title="$t('fossilThgFactorInfoBoxTitle')"
          :infobox-text="$t('fossilThgFactorInfoBox')"
          align-right
        />
        <HeaderEntry
          :title="$t('anergenicEmissionTitle')"
          unit="kg CO₂e/kWh"
          :infobox-title="$t('anergenicEmissionInfoBoxTitle')"
          :infobox-text="$t('anergenicEmissionInfoBox')"
          align-right
        />
        <HeaderEntry
          :title="$t('biogenicEmissionTitle')"
          unit="kg CO₂e/kWh"
          :infobox-title="$t('biogenicEmissionInfoBoxTitle')"
          :infobox-text="$t('biogenicEmissionInfoBox')"
          align-right
        />
        <HeaderEntry
          :title="$t('fossilShareTitle')"
          unit="%"
          :infobox-title="$t('fossilShareInfoBoxTitle')"
          :infobox-text="$t('fossilShareInfoBox')"
          align-right
        />
        <HeaderEntry
          :title="$t('renewableShareTitle')"
          unit="%"
          :infobox-title="$t('renewableShareInfoBoxTitle')"
          :infobox-text="$t('renewableShareInfoBox')"
          align-right
        />
      </template>

      <!-- Rows, data -->
      <template>
        <li v-for="(energyCarrier, i) in reidaEnergyCarriers" :key="i">
          <div :title="energyCarrier.name" class="name">
            {{ energyCarrier.name }}
          </div>
          <div class="align-right">{{ formatNumber(energyCarrier.fossil_co2, 3) }}</div>
          <div class="align-right">{{ formatNumber(energyCarrier.fossil_thg, 3) }}</div>
          <div class="align-right">{{ formatNumber(energyCarrier.anergen, 3) }}</div>
          <div class="align-right">{{ formatNumber(energyCarrier.biogen, 3) }}</div>
          <div class="align-right">{{ formatNumber(energyCarrier.fossil_share * 100, 0) }}</div>
          <div class="align-right">{{ formatNumber(energyCarrier.renewable_share * 100, 0) }}</div>
        </li>
      </template>
    </DetailList>
  </div>
</template>

<script>
import DetailList from '@/components/shared/lists/DetailList.vue'
import HeaderEntry from '@/components/shared/lists/HeaderEntry.vue'

export default {
  components: {
    DetailList,
    HeaderEntry,
  },

  props: {
    reidaEnergyCarriers: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.reida-energy-carriers-table {
  .align-right {
    text-align: right;
    justify-content: flex-end;
  }
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

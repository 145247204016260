<i18n>
{
  "de": {
    "reidaTitle": "REIDA Emissionsfaktoren",
    "sourceText": "Energieträger und Emissionsfaktoren, welche für die Erstellung des Reports nach REIDA verwendet werden.",
    "openLink": "Quelle öffnen"
  }
}
</i18n>

<template>
  <Modal wide class="c-reida-energy-carrier-modal" @close="$emit('close', $event)">
    <h2>{{ $t('reidaTitle') }}</h2>
    <ReidaEnergyCarriersTable :reida-energy-carriers="reidaEnergyCarriers" />
    <p>
      {{ $t('sourceText') }} (<a
        :href="'https://www.reida.ch/images/REIDA_pdf/REIDA_CO2_Report_methodische_Grundlagen_V12.pdf'"
        >{{ $t('openLink') }}</a
      >)
    </p>
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'
import ReidaEnergyCarriersTable from '@/components/settings/energy_carriers/ReidaEnergyCarriersTable.vue'

export default {
  components: {
    Modal,
    ReidaEnergyCarriersTable,
  },

  props: {
    reidaEnergyCarriers: {
      type: Array,
      required: true,
    },
  },

  methods: {
    //
    onClose() {
      this.$emit('close')
    },
  },
}
</script>

<i18n>
{
  "de": {
    "title": "Energieträger - Jahre"
  }
}
</i18n>

<template>
  <Modal class="c-edit-sustainability-years-modal" @close="$emit('close', $event)">
    <div v-if="error">
      <p>{{ error }}</p>
    </div>

    <template #header>
      <h2>{{ $t('title') }}</h2>
    </template>

    <EditSustainabilityYears
      :portfolio="portfolio"
      :sustainability-years="sustainabilityYears"
      @save="onSave"
      @cancel="onCancel"
      @error="onError"
    />
  </Modal>
</template>

<script>
import EditSustainabilityYears from '@/components/settings/energy_carriers/EditSustainabilityYears.vue'
import Modal from '@/components/shared/Modal.vue'

export default {
  components: {
    Modal,
    EditSustainabilityYears,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    sustainabilityYears: {
      type: Array,
    },
  },

  data() {
    return {
      error: null,
    }
  },

  methods: {
    onCancel() {
      if (this.sustainability === null) {
        this.$emit('close')
      }
    },

    onSave() {
      if (this.sustainability === null) {
        if (!this.error) {
          this.$emit('close')
        }
      }
    },

    onError(error) {
      this.error = error
    },
  },
}
</script>
